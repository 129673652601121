import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { type MetaFunction } from '@remix-run/cloudflare';
import { Links, LiveReload, Meta, Outlet, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import Header from './components/layout/header';

import './styles/app.css';

export function links() {
   return [
      { rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://eblockchainconvention.com/wp-content/uploads/2020/11/Untitled-design-33-300x300.png' },
      { rel: 'mask-icon', sizes: 'any', href: 'https://eblockchainconvention.com/wp-content/uploads/2020/11/Untitled-design-33-300x300.png', color: '#5D0FC0' },
   ];
}

export const meta: MetaFunction = () => {
   return [{ charset: 'utf-8' }, { title: 'DefiPlaza on Radix' }, { viewport: 'width=device-width,initial-scale=1' }, { 'theme-color': '#3d0283' }];
};

function App() {
   return (
      <Outlet />       
   );
}
